import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Person } from 'src/entities/person';
import { ApiService } from './../../services/api.service'
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { LeiterDetailsPage } from 'src/app/leiter-details/leiter-details.page';
import { LoadingService } from 'src/app/services/loading.service';
import { Messdiener } from 'src/entities/messdiener';
import { MessdienerDetailComponent } from '../messdiener-detail/messdiener-detail.component';
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';
import { AuthService } from 'src/app/services/auth.service';
import * as XLSX from 'ts-xlsx';

@Component({
  selector: 'app-messdiener',
  templateUrl: './messdiener.component.html',
  styleUrls: ['./messdiener.component.scss'],
})
export class MessdienerComponent implements OnInit {

  @ViewChild(MatTable) table!: MatTable<Messdiener>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('content') private content: any;
  @ViewChild('addButton') addButton;
  @ViewChild('excelInput') excelInput; 
  activeCheckboxChecked: Boolean = true;
  activeToggle: Boolean = true;
  dataSource = new MatTableDataSource<Messdiener>();
  columnsToDisplay = ['person.firstname', 'person.lastname', 'messdiener.striche'];
  messdieners: Messdiener[] = [];
  selectedMessdiener!: Messdiener;
  backupMessdiener: Messdiener = new Messdiener();
  displayDeleteButton: boolean = true;
  filterIcon: Boolean = true;

  messdienerCount: number = 100;
  currentPageSize: number = 5;
  currentPageIndex: number = 0;
  sortProperty: string = "person.lastname";
  sortDirection: string = "asc";
  searchInput = "";

  detailModal: any;
  deleteAlert: any;
  promotionAlert: any;
  otherAlertIsOpen = false;

  importFile: File;

  constructor(
    private _api: ApiService,
    public loadingService: LoadingService,
    public modalController: ModalController,
    public alertController: AlertController,
    private popover: PopoverController,
    public _auth: AuthService) { }

  async presentModal() {
    this.detailModal = await this.modalController.create({
      component: MessdienerDetailComponent,
      componentProps: {
        'messdienerComponent': this
      }
    });
    await this.detailModal.present()
    await this.detailModal.onDidDismiss().then((r) => {
      if (!r.data) {
        this.restoreMessdiener();
      }
      this.displayDeleteButton = true;
    });
  }

  async presentDeleteAlert() {
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + this.selectedMessdiener.person.firstname + " " + this.selectedMessdiener.person.lastname + " löschen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Löschen',
          handler: () => {
            this.loadingService.present();
            this._api.deleteTypeRequest('messdiener/' + this.selectedMessdiener.id)?.subscribe((res: any) => {
              this.getMessdieners();
              this.detailModal.dismiss();
              this.loadingService.dismiss();
            }, err => {
              this.loadingService.dismiss();
              console.log(err);
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }

  async promoteMessdiener() {
    this.promotionAlert = await this.alertController.create({
      header: 'Pony',
      message: "Willst du wirklich " + this.selectedMessdiener.person.firstname + " " + this.selectedMessdiener.person.lastname + " zum Pony machen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Bestätigen',
          handler: () => {
            this.loadingService.present();
            this._api.getTypeRequest('messdiener/promotion/' + this.selectedMessdiener.id, "")?.subscribe((res: any) => {
              this.getMessdieners();
              this.detailModal.dismiss();
              this.loadingService.dismiss();
            }, err => {
              this.loadingService.dismiss();
              console.log(err);
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'promotion alert'
    };
    history.pushState(modalState, null);
    await this.promotionAlert.present();
  }

  @HostListener('window:popstate', ['$event'])
  dismissDeleteAlert() {
    if (this.detailModal != null && this.otherAlertIsOpen == false) {
      if (this.deleteAlert != null) {
        this.deleteAlert.dismiss();
        this.deleteAlert = null;
      } else {
        this.detailModal.dismiss();
        this.detailModal = null;
      }
    }
  }

  ngOnInit(): void {
    var person = new Person();
    this.backupMessdiener.person = person;
    this.loadingService.present();
    this.getMessdieners();
    this.loadingService.dismiss();
  }


  public getServerData(event?: PageEvent) {
    this.currentPageSize = event.pageSize;
    this.currentPageIndex = event.pageIndex;
    this.getMessdieners();
  }

  public getMessdieners() {
    var parameter = this.getMessdienerParamter();

    this._api.getTypeRequest('messdieners', parameter.join(""))?.subscribe((res: any) => {
      if (res.status) {
        this.messdieners = [];
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          let personJson = element.person;
          let person: Person = new Person(personJson.id, personJson.firstname, personJson.lastname, personJson.birthdate, personJson.street, personJson.house_number, personJson.postal_code, personJson.city, personJson.telefon_number, personJson.mobile_number);
          let messdiener: Messdiener = new Messdiener(element.id, person, element.striche, !!+element.active);
          this.messdieners.push(messdiener);
        }
        this.messdienerCount = res.count;
        this.dataSource.data = this.messdieners;
        this.table.renderRows();
      } else {
        console.log(res);
      }
    }, err => {
      console.log(err);
    });
  }

  getMessdienerParamter(): string[]{
    let search = this.searchInput.replace(/\s/g,''); // Remove whitespace
    search = search.toLowerCase(); // Datasource defaults to lowercase matches
    let parameter: string[] =
      [
        '&index=' + this.currentPageIndex,
        '&size=' + this.currentPageSize,
        '&sortProperty=' + this.sortProperty,
        '&sortDirection=' + this.sortDirection,
        '&searchInput=' + search
      ];
    addFilterParamter("active", this.activeCheckboxChecked, this.activeToggle);

    return parameter;

    function addFilterParamter(name: string, checkBoxChecked: Boolean, toggleValue: Boolean){
      if(!checkBoxChecked){
        parameter.push("&"+name+"=");
      }else{
        parameter.push("&"+name+"="+toggleValue);
      }
    }
  }

  searchMessdiener(input: string){
    this.searchInput = input;
    this.getMessdienersAndGoToStart();
  }

  getMessdienersAndGoToStart() {
    this.paginator.pageIndex = 0;
    this.currentPageIndex = 0;
    this.getMessdieners();
  }

  onMatSortChange(sort: Sort) {
    this.sortProperty = sort.active;
    this.sortDirection = sort.direction == "" ? "asc" : sort.direction;
    this.getMessdieners();
  }


  open(messdiener: Messdiener) {
    this.backupThisMessdiener(messdiener);
    this.selectedMessdiener = messdiener;
    this.presentModal();
  }

  private backupThisMessdiener(messdiener: Messdiener) {
    this.backupMessdiener.striche = messdiener.striche;
    this.backupMessdiener.active = messdiener.active;

    this.backupMessdiener.person.firstname = messdiener.person.firstname;
    this.backupMessdiener.person.lastname = messdiener.person.lastname;
    this.backupMessdiener.person.birthdate = messdiener.person.birthdate;
    this.backupMessdiener.person.street = messdiener.person.street;
    this.backupMessdiener.person.houseNumber = messdiener.person.houseNumber;
    this.backupMessdiener.person.postalCode = messdiener.person.postalCode;
    this.backupMessdiener.person.city = messdiener.person.city;
    this.backupMessdiener.person.telefonNumber = messdiener.person.telefonNumber;
    this.backupMessdiener.person.mobileNumber = messdiener.person.mobileNumber;
  }

  private restoreMessdiener() {
    this.selectedMessdiener.striche = this.backupMessdiener.striche;
    this.selectedMessdiener.active = this.backupMessdiener.active;

    this.selectedMessdiener.person.firstname = this.backupMessdiener.person.firstname;
    this.selectedMessdiener.person.lastname = this.backupMessdiener.person.lastname;
    this.selectedMessdiener.person.birthdate = this.backupMessdiener.person.birthdate;
    this.selectedMessdiener.person.street = this.backupMessdiener.person.street;
    this.selectedMessdiener.person.houseNumber = this.backupMessdiener.person.houseNumber;
    this.selectedMessdiener.person.postalCode = this.backupMessdiener.person.postalCode;
    this.selectedMessdiener.person.city = this.backupMessdiener.person.city;
    this.selectedMessdiener.person.telefonNumber = this.backupMessdiener.person.telefonNumber;
    this.selectedMessdiener.person.mobileNumber = this.backupMessdiener.person.mobileNumber;
  }

  addMessdiener() {
    this.addButton._elementRef.nativeElement.blur();
    var person: Person = new Person();
    var messdiener: Messdiener = new Messdiener();
    messdiener.striche = 0;
    messdiener.active = true;
    messdiener.person = person;
    messdiener.person.birthdate = null;
    this.displayDeleteButton = false;
    this.open(messdiener);
  }

  saveMessdiener(strichDates: any[]) {
    this.loadingService.present();
    this.saveStriche(strichDates, this.selectedMessdiener.id);
    var messdienerValues = { 
      "id": null,
      "striche": this.selectedMessdiener.striche,
      "active": this.selectedMessdiener.active,
      "firstname": this.tryGetString(this.selectedMessdiener.person.firstname),
      "lastname": this.tryGetString(this.selectedMessdiener.person.lastname),
      "birthdate": this.tryGetDateString(this.selectedMessdiener.person.birthdate),
      "telefon_number": this.tryGetString(this.selectedMessdiener.person.telefonNumber),
      "mobile_number": this.tryGetString(this.selectedMessdiener.person.mobileNumber),
      "street": this.tryGetString(this.selectedMessdiener.person.street),
      "house_number": this.tryGetString(this.selectedMessdiener.person.houseNumber),
      "postal_code": this.tryGetNumber(this.selectedMessdiener.person.postalCode),
      "city": this.tryGetString(this.selectedMessdiener.person.city)
    };
    if (this.displayDeleteButton) {
      messdienerValues.id = this.selectedMessdiener.id;
    }
    this._api.postTypeRequest('messdiener', messdienerValues)?.subscribe((res: any) => {
      this.getMessdieners();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  saveStriche(strichDates: any[], messdienerId: string){
    var strichValues: any[] = [];
    if(strichDates.length > 0){
      strichDates.forEach(strichDate => {
        var strichValue = { 
          "messdiener_id": messdienerId,
          "date": strichDate.date
        };
        strichValues.push(strichValue);
      });
      this._api.postTypeRequest('striche', JSON.stringify(strichValues))?.subscribe((res: any) => {}, err => {
        console.log(err);
        this.loadingService.dismiss();
      });
    }
  }

  tryGetDateString(value: Date){
    if(value == undefined){
      return null
    }
    return value;
  }

  tryGetString(value:string){
    return value || null;
  }

  tryGetNumber(value:number){
    return value || null;
  }

   async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: {messdienerComponent: this}
    });
    await popover.present();
  }

  onFileSelected(event) {
    this.importFile = event.target.files[0];
  }

  importList(){
    if(!this.importFile){
      alert("Es ist keine Excel Datei ausgewählt!");
    }else{
      let fileReader = new FileReader();
      var importButton:HTMLButtonElement = document.getElementById('inputButton') as HTMLButtonElement;
      importButton.disabled = true;
      this.loadingService.present();
      fileReader.onload = (e) => {
          var arrayBuffer = fileReader.result;
          var data = new Uint8Array(arrayBuffer as any);
          var arr = new Array();
          for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {type:"binary", cellDates:true});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          var messdiener = XLSX.utils.sheet_to_json(worksheet,{raw:true, range: 1, header: ["firstname", "lastname", "birthdate", "street", "houseNumber", "postalCode", "city", "telefonNumber"], dateNF:'yyyy-mm-dd'} as any);
          var range = XLSX.utils.decode_range(worksheet['!ref']);
          range.s.c = 8;
          range.e.c = 11;
          var gruppe = XLSX.utils.sheet_to_json(worksheet,{raw:true, range: range, header: ["name", "date", "leiterFirstname", 'leiterLastname'], dateNF:'yyyy-mm-dd'} as any);
          this._api.postTypeRequest('messdieners/import', {messdiener: messdiener, gruppe: gruppe})?.subscribe((res: any) => {
            console.log(res);
            this.loadingService.dismiss();
            alert("Messdiener erfolgreich importiert!");
            this.excelInput.nativeElement.value = null;
            this.importFile = null;
            importButton.disabled = false;
            this.getMessdieners();
          }, err => {
            console.log(err);
            this.loadingService.dismiss();
            alert("Ein Fehler ist aufgetreten!");
            importButton.disabled = false;
          });
      }
      fileReader.readAsArrayBuffer(this.importFile);
    }
  }
}
